import { cva, type VariantProps } from "./style.utils";

// -----------------------------------------------------------------------------

export type TooltipContentVariants = VariantProps<
  typeof tooltipContentVariants
>;

export const tooltipContentVariants = cva({
  base: [
    // layout,
    "px-5px py-3px",
    // positioning
    "z-50",
    // radii/shadow
    "rounded-md shadow",
    // foreground
    "select-none text-sm not-italic",
    // animation
    "will-change-[transform,opacity]",
    // animation in
    "data-[state=delayed-open]:animate-in",
    "data-[state=delayed-open]:fade-in-0",
    "data-[state=delayed-open]:zoom-in-95",
    "data-[state=delayed-open]:data-[side=top]:slide-in-from-bottom-2",
    "data-[state=delayed-open]:data-[side=bottom]:slide-in-from-top-2",
    "data-[state=delayed-open]:data-[side=left]:slide-in-from-right-2",
    "data-[state=delayed-open]:data-[side=right]:slide-in-from-left-2",
    // animation out
    "data-[state=delayed-open]:data-[state=closed]:animate-out",
    "data-[state=delayed-open]:data-[state=closed]:fade-out-0",
    "data-[state=delayed-open]:data-[state=closed]:zoom-out-95",
  ],
  variants: {
    palette: {
      black: [
        // background
        "bg-shade-100",
        // foreground
        "text-white",
      ],
      white: [
        // border
        "border border-shade-15",
        // background
        "bg-white",
        // foreground
        "text-shade-100",
      ],
    },
  },
  defaultVariants: {
    palette: "black",
  },
});

// -----------------------------------------------------------------------------

export type TooltipArrowVariants = VariantProps<typeof tooltipArrowVariants>;

export const tooltipArrowVariants = cva({
  base: "h-7px w-14px",
  variants: {
    palette: {
      black: "fill-shade-100",
      white: "fill-white stroke-shade-10",
    },
  },
  defaultVariants: {
    palette: "black",
  },
});
