"use client";

import { Toaster as SonnerToaster } from "sonner";
import { cx } from "./style.utils";
import { ToastIcon } from "./toast.components";
import {
  toastActionButtonClassNames,
  toastCancelButtonClassNames,
  toastClassNames,
  toastCloseButtonClassNames,
  toastContentClassNames,
  toastDescriptionClassNames,
  toastIconClassNames,
  toastTitleClassNames,
  toastVariants,
} from "./toast.styles";

interface SonnerToasterProps
  extends React.ComponentProps<typeof SonnerToaster> {}

export interface ToasterProps
  extends Omit<
    SonnerToasterProps,
    "className" | "cn" | "icons" | "toastOptions"
  > {
  toastOptions?: Omit<
    SonnerToasterProps["toastOptions"],
    "classNames" | "unstyled"
  >;
}

export function Toaster({ toastOptions, ...props }: ToasterProps) {
  return (
    <SonnerToaster
      {...props}
      cn={cx}
      className="toast group"
      icons={{
        error: <ToastIcon intent="error" />,
        warning: <ToastIcon intent="warning" />,
        success: <ToastIcon intent="success" />,
        info: <ToastIcon intent="info" />,
        loading: <ToastIcon intent="loading" />,
      }}
      toastOptions={{
        ...toastOptions,
        unstyled: true,
        classNames: {
          error: toastVariants({ palette: "error" }),
          warning: toastVariants({ palette: "warning" }),
          success: toastVariants({ palette: "success" }),
          info: toastVariants({ palette: "info" }),
          default: toastVariants({ palette: "neutral" }),
          toast: toastClassNames,
          icon: toastIconClassNames,
          content: toastContentClassNames,
          title: toastTitleClassNames,
          description: toastDescriptionClassNames,
          actionButton: toastActionButtonClassNames,
          cancelButton: toastCancelButtonClassNames,
          closeButton: toastCloseButtonClassNames,
        },
      }}
    />
  );
}
