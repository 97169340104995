"use client";

import { forwardRef } from "react";
import {
  TooltipArrow,
  TooltipContent,
  TooltipRoot,
  TooltipTrigger,
  type TooltipContentProps,
  type TooltipRootProps,
  type TooltipTriggerProps,
  type TooltipTriggerRef,
} from "./tooltip.components";
import { type TooltipContentVariants } from "./tooltip.styles";

export { TooltipProvider } from "./tooltip.components";

export type TooltipRef = TooltipTriggerRef;

export interface TooltipProps
  extends Pick<TooltipRootProps, "open" | "defaultOpen" | "onOpenChange">,
    TooltipContentProps,
    TooltipContentVariants {
  trigger: TooltipTriggerProps["children"];
}

const Tooltip = forwardRef<TooltipRef, TooltipProps>(
  (
    {
      children,
      defaultOpen,
      onOpenChange,
      open,
      trigger,
      palette,
      ...contentProps
    },
    ref,
  ) => {
    return (
      <TooltipRoot
        defaultOpen={defaultOpen}
        open={open}
        onOpenChange={onOpenChange}
      >
        <TooltipTrigger asChild ref={ref}>
          {trigger}
        </TooltipTrigger>
        <TooltipContent
          align="center"
          side="top"
          sideOffset={4}
          {...contentProps}
          palette={palette}
        >
          {children}
          <TooltipArrow palette={palette} />
        </TooltipContent>
      </TooltipRoot>
    );
  },
);

Tooltip.displayName = "Tooltip";

export { Tooltip };
