"use client";

import {
  Arrow as RadixTooltipArrow,
  Content as RadixTooltipContent,
  Portal as RadixTooltipPortal,
  Provider as RadixTooltipProvider,
  Root as RadixTooltipRoot,
  Trigger as RadixTooltipTrigger,
  type TooltipArrowProps as RadixTooltipArrowProps,
  type TooltipContentProps as RadixTooltipContentProps,
  type TooltipPortalProps as RadixTooltipPortalProps,
  type TooltipProviderProps as RadixTooltipProviderProps,
  type TooltipProps as RadixTooltipRootProps,
  type TooltipTriggerProps as RadixTooltipTriggerProps,
} from "@radix-ui/react-tooltip";
import { forwardRef } from "react";
import {
  tooltipArrowVariants,
  tooltipContentVariants,
  type TooltipArrowVariants,
  type TooltipContentVariants,
} from "./tooltip.styles";

// -----------------------------------------------------------------------------

export type TooltipProviderRef = React.ElementRef<typeof RadixTooltipProvider>;

export interface TooltipProviderProps extends RadixTooltipProviderProps {}

export const TooltipProvider = RadixTooltipProvider;

// -----------------------------------------------------------------------------

export type TooltipRootRef = React.ElementRef<typeof RadixTooltipRoot>;

export interface TooltipRootProps extends RadixTooltipRootProps {}

export const TooltipRoot = RadixTooltipRoot;

// -----------------------------------------------------------------------------

export type TooltipTriggerRef = React.ElementRef<typeof RadixTooltipTrigger>;

export interface TooltipTriggerProps extends RadixTooltipTriggerProps {}

export const TooltipTrigger = RadixTooltipTrigger;

// -----------------------------------------------------------------------------

export type TooltipPortalRef = React.ElementRef<typeof RadixTooltipPortal>;

export interface TooltipPortalProps extends RadixTooltipPortalProps {}

export const TooltipPortal = RadixTooltipPortal;

// -----------------------------------------------------------------------------

export type TooltipContentRef = React.ElementRef<typeof RadixTooltipContent>;

export interface TooltipContentProps
  extends RadixTooltipContentProps,
    TooltipContentVariants {}

const TooltipContent = forwardRef<TooltipContentRef, TooltipContentProps>(
  ({ className, palette, ...props }, ref) => {
    return (
      <RadixTooltipContent
        {...props}
        className={tooltipContentVariants({ className, palette })}
        ref={ref}
      />
    );
  },
);

TooltipContent.displayName = "TooltipContent";

export { TooltipContent };

// -----------------------------------------------------------------------------

export type TooltipArrowRef = React.ElementRef<typeof RadixTooltipArrow>;

export interface TooltipArrowProps
  extends Omit<RadixTooltipArrowProps, "asChild">,
    TooltipArrowVariants {}

const TooltipArrow = forwardRef<TooltipArrowRef, TooltipArrowProps>(
  ({ className, palette, ...props }, ref) => {
    return (
      <RadixTooltipArrow {...props} asChild ref={ref}>
        <svg
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 8"
          preserveAspectRatio="none"
          width="1em"
          height="0.5em"
          strokeWidth={1}
          strokeLinecap="round"
          className={tooltipArrowVariants({ className, palette })}
          ref={ref}
        >
          <polyline points="0,0 12,8 24,0" />
        </svg>
      </RadixTooltipArrow>
    );
  },
);

TooltipArrow.displayName = "TooltipArrow";

export { TooltipArrow };
