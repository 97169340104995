"use client";

import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TimeSpan } from "./lib/TimeSpan";

const DefaultOnSSR = () => null;

interface ClientRenderOnlyProps extends PropsWithChildren {
  onSSR?: ReactNode | undefined;
}

export default function ClientRenderOnlys({ onSSR, children }: ClientRenderOnlyProps) {
  const [canRender, setCanRender] = useState(false);

    const persister = useMemo(() => createSyncStoragePersister({
        storage: canRender ? window.sessionStorage : null,
    }), [canRender]);

    const queryClient = useMemo(() => new QueryClient({
        defaultOptions: {
          queries: {
            gcTime: TimeSpan.fromMinutes(15).totalMilliseconds,
            staleTime: TimeSpan.fromMinutes(5).totalMilliseconds,
            refetchOnMount: true,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: 0
          },
          mutations: {
            retry: 0
          }
        },
    }), []);

  useEffect(() => {
    setCanRender(true);
  }, []);

  if (!canRender) {
      return <>{onSSR ? onSSR : <DefaultOnSSR></DefaultOnSSR>}</>
  }

    
    return <PersistQueryClientProvider 
      client={queryClient}
              persistOptions={{ persister }}>
      {children}
    </PersistQueryClientProvider>
}
