import { buttonVariants } from "./button.styles";
import { cva, cx, type VariantProps } from "./style.utils";

// -----------------------------------------------------------------------------

export const toastClassNames = cx(
  // layout
  "grid w-full grid-flow-row grid-cols-alert-callout overflow-hidden px-15px py-16px",
  // radii/shadow
  "rounded-l rounded-r-md shadow-lg",
);

export type ToastVariants = VariantProps<typeof toastVariants>;

export const toastVariants = cva({
  base: [
    // border
    "border-x-4 border-r-transparent",
    // foreground
    "text-sm text-shade-100",
    // hover reset
    "hover:data-[close-button]:*:bg-black/5",
  ],
  variants: {
    palette: {
      error: [
        // border
        "border-l-error-100",
        // background
        "bg-error-20",
        // close button
        "data-[close-button]:*:text-error-100",
      ],
      warning: [
        // border
        "border-l-warning-100",
        // background
        "bg-warning-20",
        // close button
        "data-[close-button]:*:text-warning-120",
      ],
      success: [
        // border
        "border-l-success-100",
        // background
        "bg-success-20",
        // close button
        "data-[close-button]:*:text-success-120",
      ],
      info: [
        // border
        "border-l-info-100",
        // background
        "bg-info-20",
        // close button
        "data-[close-button]:*:text-info-120",
      ],
      neutral: [
        // border
        "border-l-neutral-100",
        // background
        "bg-neutral-20",
        // close button
        "data-[close-button]:*:text-neutral-120",
      ],
    },
  },
  defaultVariants: {
    palette: "neutral",
  },
});

// -----------------------------------------------------------------------------

export const toastIconClassNames = cx(
  // layout unset
  "m-0 size-20px *:m-0",
  // layout
  "row-start-1 col-start-icon-s col-end-icon-e",
);

export type ToastIconVariants = VariantProps<typeof toastIconVariants>;

export const toastIconVariants = cva({
  variants: {
    intent: {
      error: "text-error-100",
      warning: "text-warning-100",
      success: "text-success-120",
      info: "text-info-120",
      help: "text-neutral-120",
      loading: "animate-spin text-link-hover",
    },
  },
  defaultVariants: {
    intent: "help",
  },
});

// -----------------------------------------------------------------------------

export const toastContentClassNames = cx(
  // layout
  "row-start-1 col-start-content-s col-end-content-e",
  // layout children
  "space-y-5px",
);

export const toastTitleClassNames = cx("font-semibold last:font-normal");

export const toastDescriptionClassNames = cx("");

// -----------------------------------------------------------------------------

export const toastActionButtonClassNames = buttonVariants({
  variant: "solid",
  palette: "accent",
  size: "sm",
  className: "col-start-content-s col-end-content-e mt-10px",
});

export const toastCancelButtonClassNames = buttonVariants({
  variant: "outline",
  palette: "accent",
  size: "sm",
  className: "col-start-content-s col-end-content-e mt-15px",
});

export const toastCloseButtonClassNames = cx(
  // layout unset
  "static size-30px rounded-md border-none bg-transparent p-5px *:size-full focus-visible:shadow-none",
  // layout
  "row-start-1 col-start-close-s col-end-close-e",
  // layout towards corner
  "-mb-10px -translate-y-5px translate-x-8px",
);
