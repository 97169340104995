import { forwardRef } from "react";
import { Icon, type IconProps, type IconRef } from "./icon";
import { toastIconVariants, type ToastIconVariants } from "./toast.styles";
import { toToastIconName } from "./toast.utils";

export type ToastIconRef = IconRef;

export interface ToastIconProps
  extends Omit<IconProps, "name" | "palette">,
    ToastIconVariants {}

const ToastIcon = forwardRef<ToastIconRef, ToastIconProps>(
  ({ className, intent, ...props }, ref) => {
    return (
      <Icon
        {...props}
        name={toToastIconName(intent)}
        className={toastIconVariants({ className, intent })}
        ref={ref}
      />
    );
  },
);

ToastIcon.displayName = "ToastIcon";

export { ToastIcon };
