import { IconName } from "./icon.names";
import { type ToastIconVariants } from "./toast.styles";

export type ToastIntent = ToastIconVariants["intent"];

export type ToastIconNameMap = Record<NonNullable<ToastIntent>, IconName>;

export const toastIconNameMap = {
  error: "ms-error-filled",
  warning: "ms-warning-filled",
  success: "ms-check",
  info: "ms-info",
  help: "ms-help",
  loading: "custom-spinner",
} as const satisfies ToastIconNameMap;

export function toToastIconName(intent?: ToastIntent) {
  return `${toastIconNameMap[intent ?? "help"]}` as IconName;
}
