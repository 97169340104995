"use client";
import { usePathname, useSearchParams,  } from "next/navigation";
import redirectSettings from "./redirectSettings.json";
import { useCallback, useEffect, useState } from "react";
import {isMobile, isSafari, isAndroid, isIOS} from 'react-device-detect';
import { MobileRedirectRoute, getMobileRedirectRoute } from '#app/_ui/components/mobileRedirects/MobileRedirectFunctions'

export default function MobileRedirect() {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const [currentRoute, setCurrentRoute] = useState<MobileRedirectRoute|null>(null);
    const [showBanner, setShowBanner] = useState<boolean>(false);
    const forceMobileKey: string = 'forceMobile';
    const userClosedBannerKey: string = 'dontShowBanner';

     const getRoute = useCallback(() : MobileRedirectRoute => {
        return getMobileRedirectRoute(pathname, searchParams);
    }, [pathname, searchParams]);

    useEffect(() => {
        function checkDontShowBanner() : boolean {
            const result =  checkCache(userClosedBannerKey, true);
            if(result){ removeCache(userClosedBannerKey); }
            return result;
        }

        function checkForceMobile() : boolean{ return checkCache(forceMobileKey, true); }

        const routeInfo = getRoute();
        setCurrentRoute(routeInfo);

        const result = routeInfo?.route != null && isMobile && !isSafari && !checkDontShowBanner();
        setShowBanner(result);
        if(checkForceMobile() && result) { goToMobile(routeInfo); }
    }, [getRoute]);

    // sets the provided cache type value to today's date + 30 days
    function setCache(cacheKey: string) {
        sessionStorage.setItem(cacheKey, ''+ new Date(new Date().getTime() + (2592000000)));  // 2592000000 = 30 (30 days) * 24 (hrs -> days) * 60 (min -> hrs) * 60 (sec -> min) * 1000 (ms -> sec)
    }

    //removes the provided cache type value
    function removeCache(cacheKey: string) {
        window.sessionStorage.removeItem(cacheKey);
    }

    function checkCache(cacheKey: string, ignoreDate: boolean): boolean{
        const cacheVal = window.sessionStorage.getItem(cacheKey);

        if(cacheVal == null){ return false; }
        if(ignoreDate){ return true; }

        const createdDate = new Date(Date.parse(cacheVal!));
        const currentDate = new Date();
        return currentDate <= createdDate;
    }

    function closeBanner() {
        setCache(userClosedBannerKey);
        setShowBanner(false);
    }

    function goToMobile(route : MobileRedirectRoute | null){
        if(!route?.route){ return; }

        var storeUrl = '';
        if(isAndroid){ storeUrl = redirectSettings.mobileStoreURLAndroid }
        if(isIOS && !isSafari){ storeUrl = redirectSettings.mobileStoreURLIOS }

        if(storeUrl){
            var timeOutId = setTimeout(function () {
                setTimeout(function () { //Iphone has a are you sure popup... we need to get around it with another times
                    if (document.visibilityState != 'hidden') { window.location.href = storeUrl; }
                }, 500);
            }, 2000);
            window.addEventListener("visibilitychange", () => { clearTimeout(timeOutId); })
            window.addEventListener("pagehide", () => { clearTimeout(timeOutId); });
        }

        const paramString = route.params.toString();

        window.location.href = route?.route + (paramString && '?') + paramString;
    }

    function openMobileTouched<T>(event: React.TouchEvent<T>){
        event.preventDefault();
        setCache(forceMobileKey);
        goToMobile(currentRoute);
    }

    function displayBanner(){
        const fullRoute = '' + currentRoute?.route + (currentRoute?.params.toString() && '?') + currentRoute?.params.toString();
        return <div id="mobileBanner">
        <button id="appButton" onTouchEnd={openMobileTouched}>OPEN</button>
        <button id="closeButton" onTouchEnd={e => {
            e.preventDefault();
            setCache(userClosedBannerKey);
            closeBanner();
        }}>
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="17" y1="6.23744" x2="6.23744" y2="17" stroke="#617FB3" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                <line x1="17.7626" y1="16.9038" x2="7.10211" y2="6.24335" stroke="#617FB3" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </button>
        <div id="appLink">
            {/*<img id='appIcon' src="" alt="Mobile app icon" onTouchEnd={openMobileTouched} /> TODO: does the same thing as appbutton*/}
            <p>Baird Online</p>
            <p>Open in the mobile app</p>
            <p>Redirecting to: {fullRoute}</p>
        </div>

        <div id="dragger"></div>
    </div>;
    }

    return <>{showBanner ? <>{displayBanner()} </> : ''}</>
}